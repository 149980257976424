import { getSiteFormsWithSubjects } from 'application/adapters/subjectsAdapter';
import { getDictionaryItems, getDictionaryItemsProps } from 'application/repositories/dictionaryRepository';
import { getNavigationData, GetNavigationInterface } from 'application/repositories/navigationRepository';
import { getPageContent } from 'application/repositories/pageContentRepository';
import { getSite, GetSiteProps } from 'application/repositories/siteRepository';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { PageTypeFeature } from 'features/Modules/PageType/PageTypeFeature';
import { CACHE_REVALIDATE_TIME_DEFAULT } from 'features/_constants';
import { urlsAreEqual } from 'helpers/getUrlPathname/getUrlPathname';
import logger from 'helpers/logger';
import { nodeCache } from 'helpers/nodeCache';
import { GetStaticPaths, GetStaticProps } from 'next';
import { DXPContentPage } from 'pages/_app';
import Application from 'helpers/application';
import { minimalError } from 'helpers/error/minimalError';

const Page: DXPContentPage<PageProps> = (pageProps) => {
	const { page } = pageProps ?? {};
	return (
		<LayoutFeature {...pageProps}>
			<PageTypeFeature {...page} />
		</LayoutFeature>
	);
};

export type PageProps = {
	content: Content.PageContent;
	navigation: Navigation.NavigationItem;
	dictionary: Content.DictionaryItem[];
	site: Models.Site;
};

export const getStaticPaths: GetStaticPaths = async () => {
	return {
		paths: [],
		// In the current setup fallback MUST be blocking
		// If fallback is set to true next will try to return the
		// page with an initially empty data-set, so that you can show
		// a loading page or do other operations until data is available
		// https://nextjs.org/docs/api-reference/data-fetching/get-static-paths#fallback-blocking
		fallback: 'blocking',
	};
};

export const getStaticProps: GetStaticProps<PageProps> = async ({
	params,
	preview,
	previewData: untypedPreviewData,
}) => {
	const { page, origin } = params;
	const host = atob(origin as string);
	const path = page ? (page as string[]).join('/') : '';

	const previewData = untypedPreviewData as Models.PreviewData;
	const profiler = logger.startTimer();

	if (Application.started === false) {
		logger.debug('Application.started (first page request) - Debug level log message');
		logger.info('Application.started (first page request) - Info  level log message');
		logger.warn('Application.started (first page request) - Warn  level log message');
		logger.error('Application.started (first page request) - Error  level log message');
		Application.started = true;
	}

	try {
		const dictionaryPromise = nodeCache.checkCacheBeforeFetch<Content.DictionaryItem[], getDictionaryItemsProps>(
			{
				key: 'dictionary',
			},
			{
				host,
				preview,
				previewData,
			},
			getDictionaryItems,
		);

		const navigationPromise = nodeCache.checkCacheBeforeFetch<Navigation.NavigationItem, GetNavigationInterface>(
			{
				key: `navigation-${host}`,
				ttlInMinutes: 10,
			},
			{
				host,
				preview,
				previewData,
			},
			getNavigationData,
		);

		const sitePromise = await nodeCache.checkCacheBeforeFetch<Models.Site, GetSiteProps>(
			{
				key: `site-${host}`,
			},
			{ host: host, preview, previewData },
			getSite,
		);

		const contentPromise = {
			...(await getPageContent({
				url: path,
				host,
				preview,
				previewData,
			})),
		};

		// If there are any siteForms we fetch the subjects needed for those forms here serverside to be cached
		if (contentPromise?.siteForms?.length > 0) {
			contentPromise.siteForms = await getSiteFormsWithSubjects(contentPromise.siteForms, host);
		}

		return {
			props: {
				content: contentPromise,
				navigation: await navigationPromise,
				dictionary: await dictionaryPromise,
				site: sitePromise,
				searchMetadata: contentPromise.searchMetadata,
			},
			revalidate: CACHE_REVALIDATE_TIME_DEFAULT,
		};
	} catch (error) {
		const statusCode = Number(error?.response?.status);

		// We use this to check for an infinite redirection loop, which apparently can happen.
		const preventRedirect = urlsAreEqual(path, host, error.response?.data?.url);

		if (statusCode === 404 || preventRedirect) {
			if (preventRedirect) {
				logger.info(
					'Error in getStaticProps(): Url and error.url are equal. Forcing 404 to prevent redirection loop.',
					minimalError(error),
					{ host },
					{ path },
				);
			}
			logger.debug(`[${statusCode}] in getStaticProps()`, minimalError(error), { host }, { path });

			return {
				notFound: true,
				revalidate: CACHE_REVALIDATE_TIME_DEFAULT,
			};
		}

		if (statusCode === 301 || statusCode === 302 || statusCode === 307 || statusCode === 308) {
			return {
				redirect: {
					destination: error.response?.data?.url,
					permanent: statusCode === 301 || statusCode === 307,
				},
			};
		}

		logger.error(`[${statusCode}] in getStaticProps() - `, minimalError(error), { host }, { path });
		throw error;
	} finally {
		profiler.done({ message: 'getStaticProps()', level: 'info' });
	}
};

export default Page;
