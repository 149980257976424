
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/hosts/[origin]/[[...page]]",
      function () {
        return require("private-next-pages/hosts/[origin]/[[...page]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/hosts/[origin]/[[...page]]"])
      });
    }
  